// @import "./app/styling/theme.scss";

html {
  height: 100%;
}

/* You can add global styles to this file, and also import other style files */

/*
Default Style
============================*/

:root {
  --bodyFontFamily: "Open Sans", sans-serif;
  --headingFontFamily: "Catamaran", sans-serif;
  --mainColor: #1d42d9;
  --bodyColor: #7a7e9a;
  --headingColor: #293a5c;
  --whiteColor: #ffffff;
  --blackColor: #000000;
  --darkBlueColor: #051242;
  --boxShadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  --transition: all 0.5s;
  --borderRadius: 4px;
}

body {
  padding: 0;
  margin: 0;
  font-size: 14px;
  line-height: 1.8;
  font-family: var(--bodyFontFamily);
  color: var(--bodyColor);
  overflow-x: hidden !important;
}

a {
  display: inline-block;
  transition: var(--transition);
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: var(--headingColor);
  }

  &:focus {
    text-decoration: none;
  }
}

button {
  outline: 0 !important;
  box-shadow: none;
  border: none;

  &:focus {
    box-shadow: none;
  }
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--headingColor);
  font-family: var(--headingFontFamily);
  font-weight: 700;
}

h3 {
  font-size: 21px;
  line-height: 1.5;
}

.d-table {
  width: 100%;
  height: 100%;
}

.d-table-cell {
  vertical-align: middle;
}

p {
  font-size: 15px;
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }
}

img {
  max-width: 100%;
  height: auto;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.form-control {
  height: 50px;
  color: var(--headingColor);
  border: 1px solid #e8e8e8;
  background-color: transparent;
  border-radius: 0;
  padding: 0;
  font-size: 15px;
  padding: 10px 20px;
  width: 100%;

  &::placeholder {
    color: #495057;
    font-size: 13px;
  }

  &:focus {
    color: var(--blackColor);
    background-color: transparent;
    box-shadow: unset;
    outline: 0;
    border: 1px solid var(--mainColor);
  }
}

.form-control:hover,
.form-control {
  &:focus {
    box-shadow: unset;
  }
}

textarea {
  &.form-control {
    height: auto;
    height: 250px;
  }
}

.ptb-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.pt-100 {
  padding-top: 100px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pt-70 {
  padding-top: 70px;
}

.pb-70 {
  padding-bottom: 70px;
}

.mb-30 {
  margin-bottom: 30px;
}

.pl-30 {
  padding-left: 30px;
}

.pr-30 {
  padding-right: 30px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mt-minus-70 {
  margin-top: -70px;
}

.mb-minus-70 {
  margin-bottom: -70px;
}

.ml-30 {
  margin-left: 30px;
}

.mr-30 {
  margin-right: 30px;
}

.bg-color {
  background: #f5faff;
}

@keyframes rotaed360 {
  0% {
    transform: rotateZ(0deg);
  }

  100% {
    transform: rotateZ(-360deg);
  }
}

@keyframes animationFramesOne {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }

  20% {
    transform: translate(73px, -1px) rotate(36deg);
  }

  40% {
    transform: translate(141px, 72px) rotate(72deg);
  }

  60% {
    transform: translate(83px, 122px) rotate(108deg);
  }

  80% {
    transform: translate(-40px, 72px) rotate(144deg);
  }

  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}

/*
Go Top Style*/
.go-top {
  position: fixed;
  cursor: pointer;
  top: 93%;
  right: -10%;
  background-color: var(--mainColor);
  z-index: 4;
  width: 40px;
  text-align: center;
  height: 42px;
  line-height: 42px;
  opacity: 0;
  visibility: hidden;
  transition: .9s;

  i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: 0 auto;
    color: var(--whiteColor);
    transition: 0.5s;
    font-size: 20px;

    &:last-child {
      opacity: 0;
      visibility: hidden;
      top: 60%;
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: var(--mainColor);
    opacity: 0;
    visibility: hidden;
    transition: 0.5s;
  }

  &:hover {
    color: var(--whiteColor);
    background-color: var(--headingColor);

    &::before {
      opacity: 1;
      visibility: visible;
    }

    i {
      &:first-child {
        opacity: 0;
        top: 0;
        visibility: hidden;
      }

      &:last-child {
        opacity: 1;
        visibility: visible;
        top: 50%;
      }
    }
  }

  &:focus {
    color: var(--whiteColor);

    &::before {
      opacity: 1;
      visibility: visible;
    }

    i {
      &:first-child {
        opacity: 0;
        top: 0;
        visibility: hidden;
      }

      &:last-child {
        opacity: 1;
        visibility: visible;
        top: 50%;
      }
    }
  }
}

.go-top.active {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
  right: 3%;
  top: 93%;
}

/** Section tittle Css **/
.section-title {
  position: relative;
  max-width: 60vw;
  margin: -8px auto 60px;
  text-align: center;

  span {
    color: var(--mainColor);
    font-size: 14px;
    position: relative;
    display: inline-block;
    margin-bottom: 8px;
  }

  h2 {
    font-size: 40px;
    text-transform: capitalize;
    color: var(--headingColor);
    margin-bottom: 20px;
  }
}

/** Box Btn Css **/
.box-btn {
  font-size: 16px;
  color: var(--whiteColor);
  padding: 15px 25px;
  line-height: 1;
  transition: var(--transition);
  text-transform: capitalize;
  position: relative;
  cursor: pointer;
  border-radius: 4px;
  text-align: center;
  overflow: hidden;
  z-index: 1;
  background-color: var(--mainColor);
  border: 1px solid var(--mainColor);

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 100%;
    width: 100%;
    height: 50%;
    background-color: var(--darkBlueColor);
    z-index: -1;
    transition: all 0.5s;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 100%;
    width: 100%;
    height: 50%;
    background-color: var(--darkBlueColor);
    z-index: -1;
    transition: all 0.5s;
  }

  &.border-btn {
    background-color: transparent;
    border: 1px solid var(--whiteColor);
  }

  &:hover {
    color: var(--whiteColor);

    &::before {
      left: 0;
    }

    &::after {
      right: 0;
    }
  }
}

/** Line Btn Css **/
.line-bnt {
  font-size: 14px;
  color: var(--headingColor);
  font-weight: 600;
  line-height: 1;

  &:hover {
    letter-spacing: 1px;
    color: var(--headingColor);
  }
}

/** Preloader Css **/
.loader-content {
  background: var(--darkBlueColor);
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 9999999;
  left: 0;
  top: 0;
  text-align: center;
}

.sk-folding-cube {
  margin: 20px auto;
  width: 40px;
  height: 40px;
  position: relative;
  transform: rotateZ(45deg);

  .sk-cube {
    float: left;
    width: 50%;
    height: 50%;
    position: relative;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--whiteColor);
      animation: sk-foldCubeAngle 2.4s infinite linear both;
      transform-origin: 100% 100%;

      @keyframes sk-foldCubeAngle {

        0%,
        10% {
          -webkit-transform: perspective(140px) rotateX(-180deg);
          transform: perspective(140px) rotateX(-180deg);
          opacity: 0;
        }

        25%,
        75% {
          -webkit-transform: perspective(140px) rotateX(0deg);
          transform: perspective(140px) rotateX(0deg);
          opacity: 1;
        }

        90%,
        100% {
          -webkit-transform: perspective(140px) rotateY(180deg);
          transform: perspective(140px) rotateY(180deg);
          opacity: 0;
        }
      }
    }
  }

  .sk-cube2 {
    transform: scale(1.1) rotateZ(90deg);

    &::before {
      animation-delay: 0.3s;
    }
  }

  .sk-cube3 {
    transform: scale(1.1) rotateZ(180deg);

    &::before {
      animation-delay: 0.6s;
    }
  }

  .sk-cube4 {
    transform: scale(1.1) rotateZ(270deg);

    &::before {
      animation-delay: 0.9s;
    }
  }
}

/*
Header Area Css
======================================================*/
.header-area {
  position: relative;
  padding: 15px 0;
  background: #f5faff;
  overflow: hidden;

  .header-content-right {
    position: relative;
    display: inline-flex;

    .header-contact {
      padding: 0;
      margin: 0;
      position: relative;

      li {
        display: inline-block;
        position: relative;
        font-size: 15px;
        margin-right: 20px;
        font-weight: 500;

        a {
          color: var(--darkBlueColor);

          i {
            position: relative;
            top: 2px;
            margin-right: 5px;
            font-size: 16px;
            color: var(--mainColor);
          }

          &:hover {
            color: var(--mainColor);
          }
        }

        &:last-child {
          margin-right: 0;
        }
      }

      &::before {
        position: absolute;
        width: 1px;
        height: 24px;
        content: "";
        background: var(--mainColor);
        right: -40px;
        top: 2px;
      }
    }

    .header-social {
      padding: 0;
      margin: 0;
      margin-left: 10px;

      li {
        display: inline-block;
        position: relative;
        padding-left: 10px;

        a {
          font-size: 16px;
          color: var(--mainColor);

          &:hover {
            color: var(--mainColor);
            transform: translateY(-2px);
          }
        }
      }

      &::before {
        display: none !important;
      }
    }
  }
}

/*
header-2 Area Css
======================================================*/
.header-2 {
  background: var(--mainColor);

  &.three {
    background: #000b2f;
  }

  .header-content-right {
    .header-contact {
      li {
        display: inline-block;
        position: relative;
        padding-left: 25px;
        font-size: 16px;
        margin-right: 20px;

        a {
          color: var(--whiteColor);

          i {
            position: absolute;
            display: inline-block;
            left: 0;
            top: 4px;
            color: var(--whiteColor);
            font-size: 18px;
          }

          &:hover {
            color: var(--whiteColor);
          }
        }
      }

      &::before {
        display: none;
      }
    }

    .header-social {
      padding: 0;
      margin: 0;
      margin-left: 10px;

      li {
        display: inline-block;
        position: relative;
        padding-left: 10px;

        a {
          font-size: 18px;
          color: var(--whiteColor);
          width: 35px;
          height: 35px;
          border: 1px solid var(--whiteColor);
          border-radius: 50%;
          line-height: 37px;
          text-align: center;
          transition: 0.3s;
          transition: 0.3s;

          &:hover {
            background: var(--whiteColor);
            color: #1e2a55;
          }
        }

        &:first-child {
          padding-left: 0;
        }
      }
    }
  }
}

/*
Banner Area Css
======================================================*/
.banner-area {
  position: relative;
  // //(assets/images/home-bg-2.png);
  background-size: cover;
  overflow: hidden;
  width: 100%;
  height: 800px;

  .banner-content {
    max-width: 635px;
    margin-left: auto;
    margin-top: 30px;

    h1 {
      font-size: 55px;
      color: var(--whiteColor);
      margin: 0 0 30px;
    }

    p {
      color: var(--whiteColor);
      padding-right: 0%;
    }

    .banner-btn {
      margin-top: 45px;

      .box-btn {
        margin-right: 20px;

        &::before,
        &::after {
          background: var(--whiteColor);
        }

        &:hover {
          color: #132460;
        }
      }
    }
  }

  .banner-img {
    animation: pulse 7s infinite;
  }

  .home-shape {
    .shape1 {
      position: absolute;
      top: 55%;
      left: 2%;
      animation: animationFramesOne 15s linear infinite;
    }

    .shape2 {
      position: absolute;
      top: 19%;
      left: 3%;
      animation: pulse 4s infinite;
    }

    .shape3 {
      position: absolute;
      bottom: 13%;
      left: 40%;
      animation: rotaed360 6s infinite linear;
    }

    .shape4 {
      position: absolute;
      top: 15%;
      left: 35%;
      animation: rotaed360 6s infinite linear;
    }

    .shape5 {
      position: absolute;
      top: 15%;
      left: 62%;
      animation: animationFramesOne 15s linear infinite;
    }

    .shape6 {
      position: absolute;
      bottom: 30%;
      right: 4%;
      animation: animationFramesOne 15s linear infinite;
    }
  }
}

/*
Slider 2 Area Css
======================================================*/
.slider-area-2 {
  position: relative;
  z-index: 1;

  .home-slider {
    .single-slider {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      border-bottom-left-radius: 90px;
      height: 100vh;
      position: relative;
      z-index: 1;
      border-bottom-right-radius: 90px;

      &::before {
        position: absolute;
        content: "";
        background: #000;
        opacity: 0.6;
        width: 100%;
        z-index: -1;
        height: 100%;
        top: 0;
        left: 0;
        border-bottom-left-radius: 90px;
        border-bottom-right-radius: 90px;
      }

      .slider-content {
        position: relative;
        max-width: 700px;
        margin: auto;

        h1 {
          font-size: 60px;
          color: var(--whiteColor);
          margin-bottom: 20px;
        }

        p {
          color: var(--whiteColor);
          margin-bottom: 35px;
        }
      }

      .slider-btn {
        .box-btn {
          margin-right: 20px;
          border-radius: 50px;
        }

        .border-btn {
          border-radius: 50px;

          &:hover {
            &::before {
              background-color: var(--whiteColor);
            }

            &::after {
              background-color: var(--whiteColor);
            }

            color: var(--mainColor);
          }
        }
      }
    }
  }

  .owl-item {
    &.active {
      .slider-content {
        &.one {
          h1 {
            animation: 1s .2s fadeInUpBig both;
          }

          p {
            animation: 1.5s .3s fadeInUpBig both;
          }

          a {
            animation: 2s .4s fadeInUpBig both;
          }
        }
      }
    }
  }

  .owl-nav {
    .owl-next {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: none;
      font-size: 16px;
      line-height: 50px;
      top: 50%;
      position: absolute;
      right: 1%;
      background: var(--mainColor);
      font-weight: bold;
      transition: 0.3s;
      color: var(--whiteColor);
      padding: 0 !important;
      transform: translateY(-50px);

      i {
        margin-left: 3px;
      }

      &:hover {
        background: var(--darkBlueColor);
        color: var(--whiteColor);
      }
    }

    .owl-prev {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: none;
      font-size: 16px;
      line-height: 50px;
      left: 1%;
      padding: 0 !important;
      top: 50%;
      position: absolute;
      color: var(--whiteColor);
      font-weight: bold;
      background: var(--mainColor);
      transition: 0.3s;
      transform: translateY(-50px);

      i {
        margin-right: 3px;
      }

      &:hover {
        background: var(--darkBlueColor);
        color: var(--whiteColor);
      }
    }
  }

  .single-slider-bg-1 {
    //(assets/images/home-bg-3.jpg)
  }

  .single-slider-bg-2 {
    //(assets/images/home-bg-4.jpg)
  }

  .single-slider-bg-3 {
    //(assets/images/home-bg-5.jpg)
  }
}

/*
Info Area Css
======================================================*/
.info-area {
  position: relative;

  .single-info {
    position: relative;
    transition: 0.3s;
    z-index: 1;
    margin-bottom: 30px;

    .info-img {
      img {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        box-shadow: var(--boxShadow);
      }
    }

    .content {
      position: relative;
      max-width: 85%;
      margin: auto;
      background: var(--whiteColor);
      padding: 30px;
      text-align: center;
      margin-top: -40px;
      border-radius: 4px;
      transition: 0.3s;
      z-index: 1;
      box-shadow: var(--boxShadow);

      h3 {
        margin-bottom: 0;
        color: var(--darkBlueColor);
        font-size: 20px;

        i {
          color: var(--mainColor);
          margin-right: 10px;
        }
      }

      &::before {
        position: absolute;
        content: "";
        bottom: 100%;
        left: 0;
        right: 0;
        background-color: var(--darkBlueColor);
        overflow: hidden;
        width: 100%;
        height: 0;
        transition: .5s ease;
        z-index: -1;
      }

      .arrow {
        position: absolute;
        width: 35px;
        height: 35px;
        background: var(--mainColor);
        color: var(--whiteColor);
        border-radius: 50%;
        line-height: 35px;
        text-align: center;
        left: 50%;
        top: 110%;
        transform: translate(-50%, -50%);
        opacity: 0;
        transition: 0.3s;

        a {
          color: var(--whiteColor);
        }

        i {
          position: relative;
          left: 1px;
          font-weight: normal;
        }
      }
    }

    &:hover {
      .content {
        &::before {
          bottom: 0;
          height: 100%;
        }

        h3 {
          color: var(--whiteColor);

          i {
            color: var(--whiteColor);
          }
        }

        .arrow {
          opacity: 1;
          top: 95%;
        }
      }
    }
  }
}

/*
Choose Area Css
======================================================*/
.choose-area {
  .choose-content {
    .faq-accordion {
      .panel-group {
        accordion-group {
          border: 0;
          display: block;
          position: relative;
          margin-bottom: 20px;
          box-shadow: var(--boxShadow);

          &:last-child {
            margin-bottom: 0;
          }
        }

        .panel-heading {
          .panel-title {
            margin-bottom: 0;

            a {
              background-color: var(--whiteColor);
              box-shadow: var(--boxShadow);
              padding: 20px 60px 20px 25px;
              color: var(--darkBlueColor);
              position: relative;
              border-radius: 2px;
              font-size: 18px;
              font-weight: 600;
              display: block;

              &::before {
                top: 50%;
                right: 20px;
                content: "\eb89";
                position: absolute;
                transform: translateY(-50%) rotate(0);

                font: {
                  family: "boxicons";
                  size: 20px;
                }

                ;
              }
            }
          }
        }

        .panel-collapse {
          background-color: var(--whiteColor);
          padding: 15px 30px 15px 30px;
          font-size: 16px;

          p {
            color: var(--darkBlueColor);
          }
        }

        .dropup {
          .panel-heading {
            .panel-title {
              a {
                background-color: var(--darkBlueColor);
                color: var(--whiteColor);

                &::before {
                  transform: translateY(-50%) rotate(45deg);
                }
              }
            }
          }
        }

        .collapse {
          &.in {
            display: block;
          }
        }
      }
    }
  }
}

/*
Choose Area 2 Css
======================================================*/
.home2-choose-area {
  .single-choose {
    position: relative;
    transition: 0.3s;
    overflow: hidden;
    text-align: center;
    box-shadow: var(--boxShadow);
    margin-bottom: 30px;

    .icon {
      background: var(--whiteColor);
      border-radius: 4px;
      text-align: center;
      overflow: hidden;
      margin: auto;
      box-shadow: 2px 2px 20px 4px rgba(0, 0, 0, 0.07);
      padding: 10px 20px;

      i {
        font-size: 80px;
        color: var(--darkBlueColor);
        transition: 0.3s;
      }
    }

    h3 {
      text-align: center;
      color: var(--headingColor);
      margin-bottom: 0;
      padding: 10px 5px;
      font-size: 17px;
      background-color: #f1f1f1;
    }

    &:hover {
      transform: translateY(-10px);

      .icon {
        i {
          color: var(--mainColor);
        }
      }
    }
  }

  .choose-c {
    position: relative;
    margin-top: 75px;

    .content {
      max-width: 600px;
      margin-left: 30px;

      h3 {
        font-size: 30px;
        color: var(--headingColor);
        margin-bottom: 15px;
        font-weight: 600;
      }

      p {
        color: var(--headingColor);
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .box-btn {
        border-radius: 50px;
        margin-top: 15px;
      }
    }
  }
}

/*
Home Case Area Css
======================================================*/
.home-case {
  .case {
    position: relative;

    .nav-tabset {
      border-bottom: 1px solid var(--bodyColor);
      padding-bottom: 5px;
      margin-bottom: 40px;
      text-align: center;

      .nav-tab {
        display: inline-block;
        margin-right: 30px;
        margin-bottom: 6px;
        cursor: pointer;
        font-weight: 600;
        font-size: 15px;

        span {
          color: #50607f;
        }

        &.active {
          position: relative;

          &::before {
            position: absolute;
            width: 100%;
            height: 5px;
            background: var(--mainColor);
            content: "";
            bottom: -14px;
          }

          span {
            color: var(--mainColor);
          }
        }
      }
    }
  }

  .case-list {
    .single-case {
      margin-bottom: 30px;
      transition: var(--transition);

      .case-img {
        img {
          border-top-left-radius: 6px;
          border-top-right-radius: 6px;
        }
      }

      .content {
        background: var(--whiteColor);
        box-shadow: var(--boxShadow);
        padding: 30px;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;

        h3 {
          margin-bottom: 10px;
          transition: var(--transition);
        }

        p {
          margin-bottom: 12px;
        }
      }

      &:hover {
        transform: translateY(-3px);

        h3 {
          color: var(--mainColor);
        }
      }
    }
  }
}

/*
Feature Area Css
======================================================*/
.feature-area {
  .feature-tittle {
    margin-bottom: 20px;

    span {
      color: var(--mainColor);
      display: inline-block;
      margin-top: -8px;
      margin-bottom: 8px;
    }

    h2 {
      font-size: 40px;
      margin-bottom: 15px;
    }
  }

  .contnet {
    ul {
      padding: 0;
      margin: 0;

      li {
        position: relative;
        padding-left: 30px;
        color: var(--darkBlueColor);
        font-size: 18px;
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }

        i {
          position: absolute;
          display: inline-block;
          left: 0;
          top: 0;
          color: var(--mainColor);
          font-size: 18px;
        }
      }
    }

    .box-btn {
      margin-top: 30px;
    }
  }

  .feature-img {
    animation: updown 5s infinite linear;

    @keyframes updown {
      0% {
        transform: translateY(-10px);
      }

      50% {
        transform: translateY(10px);
      }

      100% {
        transform: translateY(-10px);
      }
    }
  }
}

/*
Team Area Css
======================================================*/
.home-team-area {
  .single-team {
    position: relative;
    margin-bottom: 30px;
    transition: 0.5s;

    .team-img {
      position: relative;
      overflow: hidden;

      .social {
        position: absolute;
        text-align: center;
        margin: 0;
        padding: 0;
        z-index: 3;

        li {
          display: inline-block;
          margin: 0 5px;

          a {
            width: 35px;
            height: 35px;
            background: var(--whiteColor);
            border-radius: 50%;
            line-height: 37px;
            text-align: center;
            transition: 0.3s;
            color: var(--mainColor);
            transition: 0.3s;

            i {
              font-size: 17px;
            }

            &:hover {
              background: var(--mainColor);
              color: var(--whiteColor);
            }
          }
        }

        bottom: -200px;
        transition: 0.5s;
        position: absolute;
        width: 100%;
        left: 0;
      }
    }

    .content {
      padding: 20px;
      background-color: #f5faff;
      position: relative;
      z-index: 2;

      h3 {
        color: var(--darkBlueColor);
        margin-bottom: 0;
        transition: var(--transition);
      }

      p {
        margin-bottom: 0;
        font-size: 14px;
        transition: var(--transition);
      }

      &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 0;
        bottom: -50px;
        background: var(--darkBlueColor);
        opacity: 0.95;
        transition: 0.5s;
        z-index: -1;
        bottom: 0;
        left: 0;
      }
    }

    &:hover {
      .content {
        &::before {
          height: 150px;
        }
      }

      .social {
        bottom: 8px;
      }

      h3,
      p {
        color: var(--whiteColor);
      }
    }
  }

  .owl-dots {
    position: relative;
    margin-top: 20px;
    text-align: center;
    line-height: 1;

    .owl-dot {
      span {
        width: 10px;
        height: 10px;
        background: #d0d0d0 !important;
        border-radius: 50%;
        margin: 0 5px;
      }
    }

    .owl-dot.active span {
      background: var(--mainColor) !important;
    }
  }
}

/*
Client Area Css
======================================================*/
.client-area {
  &.c-bg {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 1;
  }

  .top-title {
    color: var(--headingColor);
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--whiteColor);
    z-index: -1;
    opacity: 0.8;
  }

  .owl-carousel .owl-item img {
    display: block;
    width: auto;
  }
}

.single-client {
  background-color: var(--whiteColor);
  padding: 30px;
  position: relative;
  text-align: center;
  margin-top: 30px;

  img {
    position: absolute;
    top: -30px;
    left: 0;
    right: 0;
    border: 1px solid var(--mainColor);
    border-radius: var(--borderRadius);
    margin: auto;
  }

  p {
    margin-top: 20px;
    margin-bottom: 15px;
  }

  h3 {
    margin-bottom: 0;
  }
}


/*
Blog Area Css
======================================================*/
.home-blog-area {
  position: relative;

  .single-blog {
    background: var(--whiteColor);
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    position: relative;
    margin-bottom: 30px;
    overflow: hidden;
    transition: 0.5s;
    box-shadow: 2px 2px 20px 4px rgba(0, 0, 0, 0.07);
    z-index: 1;

    .content {
      ul {
        padding: 0;
        margin: 0;

        li {
          font-size: 13px;
          display: inline-block;

          a {
            position: relative;
            margin-left: 25px;

            &::before {
              position: absolute;
              content: "";
              width: 2px;
              height: 15px;
              left: -16px;
              top: 3px;
              background: var(--bodyColor);
            }
          }
        }
      }

      padding: 30px;

      span {
        color: var(--headingColor);
        font-weight: 500;
      }

      h3 {
        margin-top: 10px;
        transition: var(--transition);
        margin-bottom: 0;
      }

      p {
        margin-top: 10px;
        transition: var(--transition);
      }

      .line-bnt {
        color: var(--darkBlueColor);
      }
    }

    &::after {
      position: absolute;
      content: "";
      background: var(--darkBlueColor);
      opacity: 0.8;
      bottom: 0;
      left: 100%;
      right: 0;
      width: 0;
      transition: 0.5s;
      z-index: -1;
      height: 100%;
    }

    &:hover {
      &::before {
        width: 100%;
        left: 0;
      }

      &::after {
        width: 100%;
        left: 0;
      }

      span,
      h3 {
        color: var(--whiteColor);
      }

      p {
        color: var(--whiteColor);
      }

      ul {
        li {
          color: var(--whiteColor);

          a {
            color: var(--whiteColor);
          }
        }
      }

      .line-bnt {
        color: var(--whiteColor);
      }
    }
  }

  .blog-btn {
    margin-top: 20px;

    p {
      font-size: 17px;
      color: var(--headingColor);
      margin-bottom: 0;

      a {
        color: var(--mainColor);
        text-decoration: underline;
      }
    }
  }
}

/*
Technology Area Css
======================================================*/
.technology-area {
  .technology-content {
    h2 {
      font-size: 40px;
      margin-bottom: 20px;
    }

    .box-btn {
      margin-top: 10px;
    }
  }

  .choose-img {
    position: relative;

    .technology-video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      display: flex;
    }
  }
}

.video-btn {
  display: inline-block;
  width: 70px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  border-radius: 50%;
  color: var(--mainColor);
  position: relative;
  top: 3px;
  z-index: 1;
  background-color: var(--mainColor);

  i {
    font-size: 40px;
    font-weight: 700;
    color: var(--whiteColor);
    position: absolute;
    top: 0;
    left: 3px;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 100%;
    height: 100%;
  }

  &::after,
  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    bottom: 0;
    left: 0;
    border-radius: 50%;
    background-color: var(--mainColor);
  }

  &::before {
    animation: ripple 1.6s ease-out infinite;
  }

  &::after {
    animation: ripple 1.6s ease-out infinite;
  }

  &:hover {
    background-color: var(--mainColor);

    i {
      color: var(--whiteColor);
    }
  }
}

@keyframes ripple {

  0%,
  35% {
    transform: scale(0);
    opacity: 1;
  }

  50% {
    transform: scale(1.5);
    opacity: 0.8;
  }

  100% {
    opacity: 0;
    transform: scale(2);
  }
}



/*
Solutions Area Css
======================================================*/
.solution-img {
  margin-bottom: 30px;
}

.solutions-area {
  position: relative;
  z-index: 1;
  overflow: hidden;

  .section-tittle {
    max-width: 850px;
  }

  .single-solutions {
    position: relative;
    text-align: center;
    padding: 30px 20px;
    background: var(--whiteColor);
    margin-top: 30px;
    border-radius: 5px;
    z-index: 1;
    overflow: hidden;
    transition: all 0.5s;
    box-shadow: 2px 2px 20px 4px rgba(0, 0, 0, 0.07);

    .solutions-img {
      margin-bottom: 20px;
    }

    .solutions-content {
      h3 {
        color: var(--darkBlueColor);
        margin-bottom: 10px;
      }

      p {
        color: #50607f;
        margin-bottom: 10px;
      }

      .line-bnt {
        color: var(--mainColor);
        font-size: 14px;
      }
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 100%;
      width: 100%;
      height: 100%;
      opacity: 0.8;
      background-color: var(--darkBlueColor);
      z-index: -1;
      transition: all 0.5s;
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      right: 100%;
      width: 100%;
      height: 100%;
      //(assets/images/service/hover-img.png);
      z-index: -2;
      transition: all 0.5s;
    }

    .solutions-back {
      position: absolute;
      transform: translateY(40px);
      padding-right: 20px;
    }

    &:hover {
      transform: translateY(-10px);

      h3 {
        color: var(--whiteColor);
        transform: translateY(-50px);
        opacity: 1;
      }

      p {
        color: #e9e9e9;
        opacity: 1;
        transform: translateY(-35px);
      }

      .line-bnt {
        color: #e6335a;
        text-decoration: underline;
        opacity: 1;
        transform: translateY(-25px);
      }

      .solutions-img {
        opacity: 0;
      }

      &::before {
        left: 0;
      }

      &::after {
        right: 0;
      }
    }
  }
}

/*
Facilities Area Css
======================================================*/
.facilities-area {
  padding-top: 60px;
  border-top: 1px solid #eeee;

  .mt-40 {
    margin-top: 40px;
  }

  position: relative;

  .section-tittle {
    max-width: 850px;

    p {
      color: #181818;
    }
  }

  .content {
    h3 {
      color: var(--headingColor);
      margin-bottom: 10px;
    }

    p {
      font-size: 16px;
      line-height: 1.9;
      color: #181818;
    }

    .box-btn {
      margin-top: 10px;
      padding: 15px 40px;
      border-radius: 50px;
    }
  }
}

/*
Solutions Details Area Css
======================================================*/
.solutions-details-area {
  position: relative;

  .single-solutions {
    position: relative;

    img {
      border-radius: 10px;
    }

    .content {
      margin-bottom: 25px;

      h2 {
        font-size: 25px;
        color: #121e4c;
        margin: 15px 0 15px;
      }

      p {
        color: #181818;
        padding-right: 10px;
      }
    }

    .social {
      position: absolute;
      text-align: center;
      margin: 0;
      padding: 0;
      z-index: 3;

      p {
        display: inline-block;
        font-size: 20px;
        margin-right: 10px;
        margin-bottom: 0;
      }

      li {
        display: inline-block;

        a {
          width: 35px;
          height: 35px;
          background: #121e4c;
          border-radius: 50%;
          line-height: 39px;
          text-align: center;
          transition: 0.3s;
          color: var(--whiteColor);
          transition: 0.3s;

          i {
            font-size: 17px;
          }

          &:hover {
            background: var(--mainColor);
            color: var(--whiteColor);
            transform: translateY(-10px);
          }
        }
      }

      position: relative;
      display: inline-block;
    }
  }

  .right-content {
    position: relative;

    .serarch {
      position: relative;
      box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.08);
      background: var(--whiteColor);
      padding-bottom: 20px;
      margin-bottom: 30px;

      h3 {
        padding: 15px 20px;
        color: var(--whiteColor);
        background: #121e4c;
        margin-bottom: 20px;
      }

      .search-form {
        position: relative;
        max-width: 90%;
        margin: auto;

        input#search {
          height: 50px;

          &:focus {
            box-shadow: none;
          }
        }

        button.box-btn {
          position: absolute;
          top: 0;
          right: 0;
          border: none;
          height: 100%;
          padding: 0 17px;
          font-size: 20px;
        }
      }
    }

    .recent-post {
      position: relative;
      box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.08);
      background: var(--whiteColor);

      h3 {
        padding: 15px 20px;
        color: var(--whiteColor);
        background: #121e4c;
        margin-bottom: 20px;
      }

      .post {
        padding: 20px;

        .item {
          overflow: hidden;
          margin-bottom: 10px;
          padding-bottom: 10px;
          border-bottom: 1px dashed #efefef;

          a {
            float: left;
            overflow: hidden;
            position: relative;
            margin-right: 15px;
          }
        }

        :last-child {
          border-bottom: none;
        }

        .info {
          overflow: hidden;
          margin-top: 20px;

          time {
            font-size: 13px;
            color: #929292;
            display: block;
            margin-bottom: 4px;
          }

          h4 {
            a {
              color: var(--darkBlueColor);
              margin-bottom: 0;
              line-height: 1.5;
              font-size: 14px;
              font-weight: 600;

              &:hover {
                transform: translateX(10px);
              }
            }
          }
        }
      }
    }

    .features {
      position: relative;
      box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.08);
      background: var(--whiteColor);

      h3 {
        padding: 15px 20px;
        color: var(--whiteColor);
        background: #121e4c;
        margin-bottom: 0;
      }

      .feature-list {
        margin: 0;
        padding: 30px;

        li {
          position: relative;
          padding-bottom: 10px;
          margin-bottom: 10px;
          color: #181818;
          padding-left: 23px;
          font-size: 16px;
          border-bottom: 1px dashed #efefef;
          font-weight: 500;

          &::before {
            background: #121e4c;
            position: absolute;
            height: 8px;
            width: 8px;
            content: '';
            left: 0;
            top: 9px;
            transform: rotate(45deg);
            animation: rotaed360 4s infinite linear;
          }
        }
      }
    }
  }
}

/*
Case Area Css
======================================================*/
.case-area {
  position: relative;

  .case {
    position: relative;

    .nav-tabset {
      border-bottom: 1px solid #7289b8;
      text-align: center;
    }

    .all-case {
      border-bottom: 1px solid #7289b8;

      li {
        display: inline-block;
        margin-right: 30px;
        margin-bottom: 6px;

        span {
          color: #50607f;
        }

        &:last-child {
          margin-right: 0;
        }
      }

      .active {
        position: relative;

        &::before {
          position: absolute;
          width: 100%;
          height: 5px;
          background: var(--mainColor);
          content: "";
          bottom: -9px;
        }

        span {
          color: var(--mainColor);
        }
      }
    }
  }

  .case-list {
    position: relative;

    .single-case {
      margin-top: 30px;
      position: relative;
      overflow: hidden;
      transition: 0.5s;
      z-index: 1;
      box-shadow: 2px 2px 20px 4px rgba(0, 0, 0, 0.07);

      .case-img {
        img {
          border-top-left-radius: 6px;
          border-top-right-radius: 6px;
        }
      }

      .content {
        background: var(--whiteColor);
        padding: 30px 20px;

        a {
          h3 {
            color: var(--darkBlueColor);
            transition: 0.5s;
          }
        }

        .line-bnt {
          margin-top: 10px;
          z-index: 1;
          position: relative;
        }

        p {
          bottom: -200px;
          position: absolute;
          transition: 0.5s;
          color: #f5faff;
          padding-right: 14px;
        }
      }

      &::before {
        position: absolute;
        bottom: 0;
        content: "";
        background: var(--darkBlueColor);
        overflow: hidden;
        width: 100%;
        height: 0;
        opacity: 0.95;
        transition: .5s;
      }

      &:hover {
        P {
          bottom: 65px;
        }

        &::before {
          height: 65%;
        }

        a {
          h3 {
            transform: translateY(-110px);
            color: #f5ebff;
          }
        }

        .line-bnt {
          color: #e6335a;
          text-decoration: underline;
          transform: translateY(-10px);
        }
      }
    }
  }

  .dropdown {
    margin-top: 30px;

    p {
      color: var(--headingColor);
      margin-bottom: 5px;
    }

    button {
      border: 1px solid var(--headingColor);
      background: transparent;
      padding: 8px 30px;
      color: var(--headingColor);
      font-weight: 500;
    }

    .dropdown-item {
      cursor: pointer;
    }
  }
}

/*
Case Details Area Css
======================================================*/
.case-details-area {
  position: relative;

  .single-case {
    position: relative;

    img {
      border-radius: 10px;
    }

    .case-img {
      margin-bottom: 25px;
    }

    .content {
      margin-bottom: 25px;

      h2 {
        font-size: 25px;
        color: #121e4c;
        margin: 15px 0 10px;
      }

      p {
        color: #181818;
        padding-right: 10px;
        margin-bottom: 10px;
      }
    }

    .social {
      position: absolute;
      text-align: center;
      margin: 0;
      padding: 0;
      z-index: 3;

      p {
        display: inline-block;
        font-size: 20px;
        font-weight: 500;
        margin-right: 10px;
        margin-bottom: 0;
      }

      li {
        display: inline-block;

        a {
          width: 35px;
          height: 35px;
          background: #121e4c;
          border-radius: 50%;
          line-height: 37px;
          text-align: center;
          transition: 0.3s;
          color: var(--whiteColor);
          transition: 0.3s;

          i {
            font-size: 17px;
          }

          &:hover {
            background: var(--mainColor);
            color: var(--whiteColor);
            transform: translateY(-10px);
          }
        }
      }

      position: relative;
      display: inline-block;
    }
  }

  .right-content {
    position: relative;

    .serarch {
      position: relative;
      box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.08);
      background: var(--whiteColor);
      padding-bottom: 20px;
      margin-bottom: 30px;

      h3 {
        padding: 15px 20px;
        color: var(--whiteColor);
        background: #121e4c;
        margin-bottom: 20px;
      }

      .search-form {
        position: relative;
        max-width: 90%;
        margin: auto;

        input#search {
          height: 50px;

          &:focus {
            box-shadow: none;
          }
        }

        button.box-btn {
          position: absolute;
          top: 0;
          right: 0;
          border: none;
          height: 100%;
          padding: 0 17px;
          font-size: 20px;
        }
      }
    }

    .recent-post {
      position: relative;
      box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.08);
      background: var(--whiteColor);

      h3 {
        padding: 15px 20px;
        color: var(--whiteColor);
        background: #121e4c;
        margin-bottom: 20px;
      }

      .post {
        padding: 20px;

        .item {
          overflow: hidden;
          margin-bottom: 10px;
          padding-bottom: 10px;
          border-bottom: 1px dashed #efefef;

          a {
            float: left;
            overflow: hidden;
            position: relative;
            margin-right: 15px;
          }
        }

        :last-child {
          border-bottom: none;
        }

        .info {
          overflow: hidden;
          margin-top: 20px;

          time {
            font-size: 13px;
            color: #929292;
            display: block;
            margin-bottom: 4px;
          }

          h4 {
            a {
              color: var(--darkBlueColor);
              margin-bottom: 0;
              line-height: 1.5;
              font-size: 14px;
              font-weight: 600;

              &:hover {
                transform: translateX(10px);
              }
            }
          }
        }
      }
    }

    .features {
      margin-top: 30px;
      position: relative;
      box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.08);
      background: var(--whiteColor);

      h3 {
        padding: 15px 20px;
        color: var(--whiteColor);
        background: #121e4c;
        margin-bottom: 0;
      }

      .feature-list {
        margin: 0;
        padding: 30px;

        li {
          position: relative;
          padding-bottom: 10px;
          margin-bottom: 10px;
          color: #181818;
          padding-left: 23px;
          font-size: 16px;
          border-bottom: 1px dashed #efefef;
          font-weight: 500;

          &::before {
            background: #121e4c;
            position: absolute;
            height: 8px;
            width: 8px;
            content: '';
            left: 0;
            top: 9px;
            transform: rotate(45deg);
            animation: rotaed360 4s infinite linear;
          }
        }
      }
    }
  }
}

/*
Blog Area Css
======================================================*/
.blog-area {
  position: relative;
  transition: 0.3s;
  overflow: hidden;

  .single-blog {
    background: var(--whiteColor);
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    position: relative;
    margin-top: 30px;
    overflow: hidden;
    transition: 0.5s;
    box-shadow: 2px 2px 20px 4px rgba(0, 0, 0, 0.07);
    z-index: 1;

    .content {
      ul {
        padding: 0;
        margin: 0;

        li {
          font-size: 13px;
          display: inline-block;

          a {
            position: relative;
            margin-left: 25px;

            &::before {
              position: absolute;
              content: "";
              width: 2px;
              height: 15px;
              left: -16px;
              top: 2px;
              background: var(--darkBlueColor);
            }
          }
        }
      }

      padding: 30px;

      span {
        color: var(--headingColor);
        font-weight: 500;
      }

      h3 {
        color: var(--darkBlueColor);
        margin-top: 10px;
      }

      p {
        margin-top: 10px;
        color: #50607f;
      }

      .line-bnt {
        color: var(--darkBlueColor);
      }
    }

    &::after {
      position: absolute;
      content: "";
      background: var(--darkBlueColor);
      opacity: 0.8;
      bottom: 0;
      left: 100%;
      right: 0;
      width: 0;
      transition: 0.5s;
      z-index: -1;
      height: 100%;
    }

    &:hover {
      &::before {
        width: 100%;
        left: 0;
      }

      &::after {
        width: 100%;
        left: 0;
      }

      span,
      h3 {
        color: #f5ebff;
      }

      p {
        color: var(--whiteColor);
      }

      ul {
        li {
          color: #cbcbcb;
        }
      }
    }
  }
}

/*
Blog Details Area Style
=====================================================*/
.blog-details-area {
  .blog-details-desc {
    .article-image {
      position: relative;
      background-color: var(--headingColor);
    }

    .article-content {
      margin-top: 30px;

      .entry-meta {
        margin-bottom: -10px;

        ul {
          padding-left: 0;
          margin-bottom: 0;
          list-style-type: none;

          li {
            position: relative;
            display: inline-block;
            color: var(--mainColor);
            margin-right: 21px;
            font-size: 14px;

            span {
              display: inline-block;
              color: var(--mainColor);
              font-weight: 500;
            }

            a {
              display: inline-block;
              color: #666666;
              font-size: 13px;

              &:hover {
                color: var(--mainColor);
              }
            }

            i {
              color: var(--mainColor);
              margin-right: 2px;
            }

            &::before {
              content: '';
              position: absolute;
              top: 11px;
              right: -15px;
              width: 6px;
              height: 1px;
              background: var(--mainColor);
            }

            &:last-child {
              margin-right: 0;

              &::before {
                display: none;
              }
            }
          }
        }
      }

      h3 {
        margin-bottom: 13px;
        margin-top: 25px;
        font-size: 30px;
      }

      .wp-block-gallery.columns-3 {
        padding-left: 0;
        list-style-type: none;
        display: flex;
        flex-wrap: wrap;
        margin-right: -10px;
        margin-left: -10px;
        margin-bottom: 30px;
        margin-top: 30px;

        li {
          flex: 0 0 33.3333%;
          max-width: 33.3333%;
          padding-right: 10px;
          padding-left: 10px;

          figure {
            margin-bottom: 0;
          }
        }
      }
    }

    .article-footer {
      display: flex;
      flex-wrap: wrap;
      margin-top: 30px;

      .article-tags {
        flex: 0 0 50%;
        max-width: 50%;

        span {
          display: inline-block;
          color: var(--mainColor);
          font-size: 20px;
          margin-right: 5px;
          position: relative;
          top: 4px;
        }

        a {
          display: inline-block;
          color: #666666;
          font-weight: 600;

          &:hover {
            color: var(--mainColor);
          }
        }
      }

      .article-share {
        flex: 0 0 50%;
        max-width: 50%;

        .social {
          padding-left: 0;
          list-style-type: none;
          text-align: right;
          margin-bottom: 0;

          li {
            margin-left: 3px;
            display: inline-block;

            span {
              display: inline-block;
              margin-right: 2px;
              font-weight: 500;
              color: #666666;
            }

            a {
              display: block;
              color: var(--whiteColor);
              width: 32px;
              height: 32px;
              line-height: 33px;
              border-radius: 50%;
              background-color: var(--mainColor);
              text-align: center;
              font-size: 14px;

              &:hover {
                animation: rubberBand 1s linear;
              }
            }
          }
        }
      }
    }

    .post-navigation {
      margin-top: 30px;
    }
  }

  blockquote {
    overflow: hidden;
    background-color: #f7f7f7;
    padding: 50px !important;
    position: relative;
    z-index: 1;
    margin-bottom: 20px;
    margin-top: 20px;
    border-radius: var(--borderRadius);

    p {
      color: var(--headingColor);
      line-height: 1.6;
      margin-bottom: 0;
      font-style: italic;
      font-weight: 600;
      font-size: 17px !important;
      text-align: center;
    }

    cite {
      display: none;
    }

    &::before {
      color: var(--mainColor);
      content: "\ee34";
      position: absolute;
      left: 50px;
      top: -50px;
      z-index: -1;
      font-family: 'boxicons';
      font-size: 140px;
      font-weight: 900;
      opacity: 0.5;
    }

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 3px;
      background-color: var(--mainColor);
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  .post-navigation {
    border-top: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;
    padding-top: 20px;
    padding-bottom: 20px;

    .navigation-links {
      display: flex;
      flex-wrap: wrap;

      .nav-previous {
        flex: 0 0 50%;
        max-width: 50%;

        a {
          i {
            margin-right: 0;
            font-size: 20px;
            position: relative;
            top: 4px;
          }
        }
      }

      .nav-next {
        flex: 0 0 50%;
        max-width: 50%;
        text-align: right;

        a {
          i {
            margin-left: 0;
            font-size: 20px;
            position: relative;
            top: 4px;
          }
        }
      }

      div {
        a {
          display: inline-block;
          font-weight: 600;
          color: var(--headingColor);

          &:hover {
            color: var(--mainColor);
          }
        }
      }
    }
  }

  .comments-area {
    padding: 25px;
    margin-top: 30px;
    box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.08);
    background-color: var(--whiteColor);

    .comments-title {
      position: relative;
      margin-bottom: 30px;
      line-height: initial;
      font-size: 22px;

      &::before {
        content: '';
        height: 25px;
        width: 3px;
        left: -25px;
        position: absolute;
        background: var(--mainColor);
        top: 50%;
        transform: translateY(-50%);
      }
    }

    ol {
      padding: 0;
      margin: 0;
      list-style-type: none;
    }

    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;
    }

    .comment-list {
      padding: 0;
      margin: 0;
      list-style-type: none;
    }

    .children {
      margin-left: 40px;
    }

    .comment-body {
      border-bottom: 1px solid #eeeeee;
      padding-left: 65px;
      font-size: 14px;
      margin-bottom: 20px;
      padding-bottom: 20px;

      .reply {
        margin-top: 15px;

        a {
          border: 1px solid #ded9d9;
          color: var(--headingColor);
          display: inline-block;
          padding: 5px 20px;
          border-radius: 30px;
          text-transform: uppercase;
          position: relative;
          z-index: 1;
          font-size: 13px;
          font-weight: 600;

          &:hover {
            color: var(--whiteColor);
            background-color: var(--mainColor);
            border-color: var(--mainColor);
          }
        }
      }
    }

    .comment-meta {
      margin-bottom: .8em;
    }

    .comment-author {
      font-size: 16px;
      margin-bottom: 0.4em;
      position: relative;
      z-index: 2;

      .avatar {
        height: 50px;
        left: -65px;
        position: absolute;
        width: 50px;
        border-radius: 50%;
      }

      .fn {
        font-weight: 600;
        color: var(--headingColor);
        font-size: 18px;
      }

      .says {
        display: none;
      }
    }

    .comment-metadata {
      color: #666666;
      letter-spacing: 0.01em;
      text-transform: uppercase;
      font-size: 13px;
      font-weight: 500;

      a {
        color: #666666;
        font-size: 11px;

        &:hover {
          color: var(--mainColor);
        }
      }
    }

    .comment-content {
      p {
        font-size: 14px;
      }
    }

    .comment-respond {
      margin-top: 30px;

      .comment-reply-title {
        margin-bottom: 15px;
        position: relative;
        font-size: 22px;

        &::before {
          content: '';
          height: 25px;
          width: 3px;
          left: -25px;
          position: absolute;
          background: var(--mainColor);
          top: 50%;
          transform: translateY(-50%);
        }

        #cancel-comment-reply-link {
          display: inline-block;
        }
      }

      .comment-form {
        overflow: hidden;
      }

      .comment-form-comment {
        margin-bottom: 30px;
        float: left;
        width: 100%;
      }

      label {
        display: block;
        font-weight: 500;
        color: var(--headingColor);
        margin-bottom: 5px;
      }

      input[type="datetime-local"] {
        display: block;
        width: 100%;
        background-color: #f7f7f7;
        border: 1px solid #eeeeee;
        padding: 0.625em 0.7375em;
        outline: 0;
        transition: 0.5s;

        &:focus {
          border-color: var(--mainColor);
        }
      }

      input[type="week"] {
        display: block;
        width: 100%;
        background-color: #f7f7f7;
        border: 1px solid #eeeeee;
        padding: 0.625em 0.7375em;
        outline: 0;
        transition: 0.5s;

        &:focus {
          border-color: var(--mainColor);
        }
      }

      input[type="month"] {
        display: block;
        width: 100%;
        background-color: #f7f7f7;
        border: 1px solid #eeeeee;
        padding: 0.625em 0.7375em;
        outline: 0;
        transition: 0.5s;

        &:focus {
          border-color: var(--mainColor);
        }
      }

      input[type="text"] {
        display: block;
        width: 100%;
        background-color: #f7f7f7;
        border: 1px solid #eeeeee;
        padding: 0.625em 0.7375em;
        outline: 0;
        transition: 0.5s;

        &:focus {
          border-color: var(--mainColor);
        }
      }

      input[type="email"] {
        display: block;
        width: 100%;
        background-color: #f7f7f7;
        border: 1px solid #eeeeee;
        padding: 0.625em 0.7375em;
        outline: 0;
        transition: 0.5s;

        &:focus {
          border-color: var(--mainColor);
        }
      }

      input[type="url"] {
        display: block;
        width: 100%;
        background-color: #f7f7f7;
        border: 1px solid #eeeeee;
        padding: 0.625em 0.7375em;
        outline: 0;
        transition: 0.5s;

        &:focus {
          border-color: var(--mainColor);
        }
      }

      input[type="password"] {
        display: block;
        width: 100%;
        background-color: #f7f7f7;
        border: 1px solid #eeeeee;
        padding: 0.625em 0.7375em;
        outline: 0;
        transition: 0.5s;

        &:focus {
          border-color: var(--mainColor);
        }
      }

      input[type="search"] {
        display: block;
        width: 100%;
        background-color: #f7f7f7;
        border: 1px solid #eeeeee;
        padding: 0.625em 0.7375em;
        outline: 0;
        transition: 0.5s;

        &:focus {
          border-color: var(--mainColor);
        }
      }

      input[type="tel"] {
        display: block;
        width: 100%;
        background-color: #f7f7f7;
        border: 1px solid #eeeeee;
        padding: 0.625em 0.7375em;
        outline: 0;
        transition: 0.5s;

        &:focus {
          border-color: var(--mainColor);
        }
      }

      input[type="number"] {
        display: block;
        width: 100%;
        background-color: #f7f7f7;
        border: 1px solid #eeeeee;
        padding: 0.625em 0.7375em;
        outline: 0;
        transition: 0.5s;

        &:focus {
          border-color: var(--mainColor);
        }
      }

      textarea {
        display: block;
        width: 100%;
        background-color: #f7f7f7;
        border: 1px solid #eeeeee;
        padding: 0.625em 0.7375em;
        outline: 0;
        transition: 0.5s;

        &:focus {
          border-color: var(--mainColor);
        }
      }

      .comment-form-author {
        float: left;
        width: 50%;
        padding-right: 10px;
        margin-bottom: 20px;
      }

      .comment-form-email {
        float: left;
        width: 50%;
        padding-left: 12px;
        margin-bottom: 20px;
      }

      .comment-form-url {
        float: left;
        width: 100%;
        margin-bottom: 20px;
      }

      .comment-form-cookies-consent {
        width: 100%;
        float: left;
        position: relative;
        padding-left: 20px;
        margin-bottom: 20px;

        input {
          position: absolute;
          left: 0;
          top: 6px;
        }

        label {
          display: inline-block;
          margin: 0;
          color: #666666;
          font-weight: normal;
        }
      }

      .form-submit {
        float: left;
        width: 100%;

        input {
          background: var(--mainColor);
          border: none;
          color: var(--whiteColor);
          padding: 14px 30px 12px;
          display: inline-block;
          cursor: pointer;
          outline: 0;
          border-radius: 0;
          text-transform: uppercase;
          transition: 0.5s;
          font-weight: 500;
          font-size: 14px;

          &:hover {
            color: var(--whiteColor);
            background-color: var(--headingColor);
          }

          &:focus {
            color: var(--whiteColor);
            background-color: var(--mainColor);
          }
        }
      }
    }
  }

  /*
Sidebar Widget Area Style
================================*/
  .widget-area {
    .widget {
      margin-top: 35px;
      background-color: var(--whiteColor);
      box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.08);

      .post-wrap {
        padding: 20px;
      }

      &:first-child {
        margin-top: 0;
      }

      .widget-title {
        text-transform: capitalize;
        position: relative;
        font-size: 20px;
        margin-bottom: 0;
        background-color: #ececec;
        padding: 10px 20px;
        color: var(--headingColor);
        position: relative;

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 2px;
          height: 100%;
          background-color: var(--mainColor);
        }
      }
    }

    .widget_search {
      form {
        position: relative;

        label {
          display: block;
          margin-bottom: 0;
        }

        .screen-reader-text {
          display: none;
        }

        .search-field {
          background-color: transparent;
          height: 50px;
          padding: 6px 15px;
          border: 1px solid #eeeeee;
          width: 100%;
          display: block;
          outline: 0;
          transition: 0.5s;

          &:focus {
            border-color: var(--mainColor);
          }
        }

        button {
          position: absolute;
          right: 0;
          outline: 0;
          bottom: 0;
          height: 50px;
          width: 50px;
          border: none;
          color: var(--whiteColor);
          background-color: var(--mainColor);
          transition: 0.5s;
          cursor: pointer;

          &:hover {
            background-color: var(--headingColor);
          }
        }
      }
    }

    .widget-peru-posts-thumb {
      position: relative;
      overflow: hidden;

      .item {
        overflow: hidden;
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #efefef;

        &:last-child {
          margin-bottom: 0;
          border-bottom: none;
          padding-bottom: 0;
        }

        .thumb {
          float: left;
          height: 80px;
          overflow: hidden;
          position: relative;
          width: 80px;
          margin-right: 15px;

          .fullimage {
            width: 80px;
            height: 80px;
            display: inline-block;
            background-size: cover !important;
            background-repeat: no-repeat;
            background-position: center center !important;
            position: relative;
            background-color: var(--headingColor);
          }

          .fullimage.bg1 {
            // //: url(assets/images/blog-details/popular-posts-1.png);
          }

          .fullimage.bg2 {
            // //: url(assets/images/blog-details/popular-posts-2.png);
          }

          .fullimage.bg3 {
            //: url(assets/images/blog-details/popular-posts-3.png);
          }

          .fullimage.bg4 {
            //: url(assets/images/blog-details/popular-posts-4.png);
          }
        }

        .info {
          overflow: hidden;

          span {
            display: block;
            color: #666666;
            text-transform: uppercase;
            margin-top: 5px;
            margin-bottom: 5px;
            font-size: 12px;
            font-weight: 500;
          }

          .title {
            margin-bottom: 0;
            line-height: 1.5;
            font-size: 14px;
            font-weight: 600;

            a {
              display: inline-block;
              color: #474c40;

              &:hover {
                color: var(--mainColor);
              }
            }
          }
        }
      }
    }

    .widget_recent_entries {
      ul {
        padding: 0;
        margin: 0;
        list-style-type: none;

        li {
          position: relative;
          margin-bottom: 12px;
          color: #666666;
          padding-left: 17px;
          line-height: 1.5;
          font-size: 15px;
          font-weight: 600;

          &:last-child {
            margin-bottom: 0;
          }

          &::before {
            background: var(--mainColor);
            position: absolute;
            height: 7px;
            width: 7px;
            content: '';
            left: 0;
            top: 7px;
          }

          .post-date {
            display: block;
            font-size: 13px;
            color: #666666;
            margin-top: 4px;
          }
        }
      }
    }

    .widget_categories {
      ul {
        padding: 0;
        margin: 0;
        list-style-type: none;

        li {
          position: relative;
          padding-bottom: 10px;
          margin-bottom: 10px;
          color: #666666;
          padding-left: 20px;
          font-size: 15px;
          font-weight: 600;
          border-bottom: 1px solid #efefef;

          &:last-child {
            margin-bottom: 0;
            border-bottom: none;
          }

          &::before {
            background: var(--mainColor);
            position: absolute;
            height: 10px;
            width: 10px;
            content: '';
            left: 0;
            top: 9px;
            clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
          }

          a {
            display: block;
            color: #666666;
            font-weight: normal;
            font-size: 14px;

            span {
              float: right;
              font-size: 13px;
            }

            &:hover {
              color: var(--mainColor);
            }
          }

          .post-count {
            float: right;
          }
        }
      }
    }

    .widget_meta {
      ul {
        padding: 0;
        margin: 0;
        list-style-type: none;

        li {
          position: relative;
          padding-bottom: 10px;
          margin-bottom: 10px;
          color: #666666;
          padding-left: 17px;
          font-size: 15px;
          font-weight: 500;
          border-bottom: 1px solid #efefef;

          a {
            color: var(--bodyColor);

            &:hover {
              color: var(--mainColor);
            }
          }

          &:last-child {
            margin-bottom: 0;
          }

          &::before {
            background: var(--mainColor);
            position: absolute;
            height: 10px;
            width: 10px;
            content: '';
            left: 0;
            top: 9px;
            clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
          }
        }
      }
    }

    .widget_tag_cloud {
      .widget-title {
        margin-bottom: 0;
      }
    }

    .tagcloud {
      a {
        display: inline-block;
        color: #666666;
        font-weight: normal;
        font-size: 14px !important;
        padding: 7px 10px;
        border: 1px dashed #eeeeee;
        margin-top: 10px;
        margin-right: 10px;
        text-transform: capitalize;

        &:hover {
          background-color: var(--mainColor);
          color: var(--whiteColor);
        }

        &:focus {
          background-color: var(--mainColor);
          color: var(--whiteColor);
          border-color: var(--mainColor);
        }
      }
    }

    .widget_services_list {
      ul {
        padding-left: 0;
        list-style-type: none;
        margin-bottom: 0;
        box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.04);
        background-color: var(--whiteColor);

        li {
          a {
            display: block;
            position: relative;
            border-bottom: 1px solid #eeeeee;
            padding-top: 15px;
            padding-bottom: 15px;
            padding-left: 15px;
            padding-right: 35px;
            color: #5d6576;
            z-index: 1;
            font-weight: 500;

            &::before {
              content: "";
              position: absolute;
              left: 0;
              top: 0;
              width: 0;
              height: 100%;
              background: #252920;
              transition: 0.5s;
              opacity: 0;
              visibility: hidden;
            }

            i {
              position: absolute;
              right: 15px;
              top: 50%;
              transform: translateY(-50%);
            }

            &:hover {
              background-color: var(--mainColor);
              color: var(--whiteColor);
              padding-left: 20px;

              &::before {
                opacity: 1;
                visibility: visible;
                width: 5px;
              }
            }
          }

          a.active {
            background-color: var(--mainColor);
            color: var(--whiteColor);
            padding-left: 20px;

            &::before {
              opacity: 1;
              visibility: visible;
              width: 5px;
            }
          }

          &:last-child {
            a {
              border-bottom: none;
            }
          }
        }
      }
    }

    .widget_download {
      ul {
        padding-left: 0;
        list-style-type: none;
        margin-bottom: 0;
        box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.04);
        background-color: var(--whiteColor);

        li {
          a {
            display: block;
            position: relative;
            border-bottom: 1px solid #eeeeee;
            padding-top: 15px;
            padding-bottom: 15px;
            padding-left: 15px;
            padding-right: 35px;
            color: #5d6576;
            z-index: 1;
            font-weight: 500;

            &::before {
              content: "";
              position: absolute;
              left: 0;
              top: 0;
              width: 0;
              height: 100%;
              background: #252920;
              transition: 0.5s;
              opacity: 0;
              visibility: hidden;
            }

            i {
              position: absolute;
              right: 15px;
              top: 50%;
              transform: translateY(-50%);
            }

            &:hover {
              background-color: var(--mainColor);
              color: var(--whiteColor);
              padding-left: 20px;

              &::before {
                opacity: 1;
                visibility: visible;
                width: 5px;
              }
            }
          }

          a.active {
            background-color: var(--mainColor);
            color: var(--whiteColor);
            padding-left: 20px;

            &::before {
              opacity: 1;
              visibility: visible;
              width: 5px;
            }
          }

          &:last-child {
            a {
              border-bottom: none;
            }
          }
        }
      }
    }

    .info time {
      font-size: 13px;
      color: #929292;
      display: block;
      margin-bottom: 4px;
    }
  }
}

/*
FAQ Area Css
======================================================*/
.faq-area {
  position: relative;
  overflow: hidden;
  box-shadow: 2px 2px 20px 4px rgba(0, 0, 0, 0.07);

  .section-tittle {
    text-align: center;

    h2 {
      margin-bottom: 30px;
    }
  }

  .faq-accordion {
    .accordion {
      padding: 0;
      margin: 0;

      .accordion-item {
        display: block;
        margin-bottom: 12px;
        position: relative;

        &:last-child {
          margin-bottom: 0;
        }

        .accordion-title {
          padding: 15px 60px 15px 70px;
          color: var(--darkBlueColor);
          position: relative;
          background: var(--whiteColor);
          border-radius: 2px;
          font-size: 18px;
          font-weight: 600;
          display: block;

          i {
            position: absolute;
            right: 30px;
            top: 50%;
            transform: translateY(-50%);
            color: var(--darkBlueColor);
            transition: 0.5s;
            font-size: 16px;
          }

          &.active {
            background-color: var(--whiteColor);

            i {
              &::before {
                content: "\ec4e";
              }
            }
          }
        }

        .accordion-content {
          display: none;
          background-color: var(--whiteColor);
          margin-bottom: 0;
          padding: 15px 60px 15px 30px;
          font-size: 16px;
          z-index: 1;
          color: #50607f;
          position: relative;

          &.show {
            display: block;
          }

          &::before {
            content: "";
            width: 75%;
            height: 2px;
            background: var(--bodyColor);
            top: 5px;
          }
        }
      }

      .icon {
        position: absolute;
        z-index: 2;
        width: 40px;
        height: 40px;
        background: #f5ebff;
        text-align: center;
        font-size: 25px;
        line-height: 40px;
        color: var(--darkBlueColor);
        top: 10px;
        left: 15px;
      }
    }
  }
}

/*
Signup Area Css
======================================================*/
.signup-area {
  .signup-form {
    box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
    padding: 40px;
    max-width: 540px;
    background: var(--whiteColor);
    border-radius: 10px;
    margin: auto;

    .form-control {
      border-radius: 4px;
      height: 50px;
      padding: 10px 20px;

      &:focus {
        box-shadow: none;
      }
    }

    .box-btn {
      margin-bottom: 30px;

      i {
        position: relative;
        top: 1px;
      }
    }

    .google {
      padding: 15px 25px;
      background: #dd4b39;
    }

    .facebook {
      padding: 15px 17px;
      background: #3b5999;
    }

    .twitter {
      padding: 15px 25px;
      background: #55acee;
    }

    .signup-btn {
      padding: 15px 50px;
      margin-bottom: 10px;
      margin-top: 20px;
    }
  }

  .privecy-txt {
    font-size: 14px;

    label {
      position: relative;
      top: -2px;
      margin-left: 5px;
    }
  }

  p.al-acc {
    margin-bottom: 0;
    font-size: 14px;
  }

  .section-tittle {
    margin-bottom: 30px;
  }
}

/*
Price Area Css
======================================================*/
.price-area {
  position: relative;
  z-index: 1;
  overflow: hidden;

  .single-price {
    text-align: center;
    position: relative;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
    border-radius: 5px;
    transition: 0.3s;

    .price-head {
      padding: 50px 0 40px;
      box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
      border-radius: 5px;
      background: var(--darkBlueColor);

      h2 {
        font-size: 25px;
        margin-bottom: 0;
        color: var(--whiteColor);
      }

      p {
        font-size: 35px;
        font-weight: bold;
        color: var(--whiteColor);
        margin-bottom: 0;

        span {
          font-size: 16px;
        }
      }
    }

    .price-list {
      text-align: center;
      margin: 0;
      padding: 20px 0;

      li {
        p {
          margin-bottom: 0;
          padding: 3px 0;
          color: #616161;
        }
      }
    }

    .box-btn {
      background: var(--darkBlueColor);
      color: var(--whiteColor);
      margin-top: 0px;
      margin-bottom: 30px;
      border: 1px solid transparent;
    }

    &:hover {
      transform: translateY(-10px);

      .box-btn {
        border: 1px dashed var(--mainColor);
        color: var(--mainColor);
        background: var(--whiteColor);

        &:hover {
          color: var(--whiteColor);
        }
      }
    }
  }
}

/*
Testimonials Area Css
======================================================*/
.testimonials-area {
  .section-tittle {
    margin-bottom: 50px;

    p {
      margin: 0 15%;
    }
  }

  .single-testimonials {
    box-shadow: var(--boxShadow);
    padding: 20px 20px;
    position: relative;
    text-align: left;
    overflow: hidden;
    margin-bottom: 20px;
    transition: var(--transition);

    .testimonials-head {
      .testimonials-img {
        border: 1px solid var(--mainColor);
        padding: 6px;
        overflow: hidden;
      }

      .content {
        margin-top: 0;
        position: relative;
        top: 2px;

        h2 {
          color: #181818;
          font-family: "Poppins", sans-serif;
          position: relative;
          font-size: 21px;
          margin-bottom: 5px;
        }

        .rate {
          padding: 0;
          margin: 0;

          li {
            display: inline-block;

            i {
              font-size: 15px;
              color: #ffc107;
            }
          }
        }

        span {
          font-size: 15px;
        }
      }
    }

    .testimonials-foot {
      p {
        margin-bottom: 0;
        color: #616161;
      }
    }

    &:hover {
      transform: translateY(-5px);
    }

    hr {
      margin-top: 18px !important;
      margin-bottom: 8px !important;
    }
  }
}

/*
Terms Area Css
======================================================*/
.terms-area,
.privecy-area {
  position: relative;
  overflow: hidden;

  h2 {
    color: var(--darkBlueColor);
    margin-bottom: 15px;
    font-size: 30px;
  }
}

/*
Gallery Area Css
======================================================*/
.gallery-area {
  position: relative;
  overflow: hidden;
  z-index: 1;

  .single-gall {
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;

    .gall-img {
      height: 400px;
      border-radius: 5px;
      overflow: hidden;
      transition: 0.3s;

      img {
        border-radius: 5px;
      }
    }

    .gall-content {
      position: absolute;
      text-align: center;
      background: var(--whiteColor);
      padding: 10px 20px;
      bottom: 20px;
      transition: 0.5s;
      bottom: -100px;

      h3 {
        color: #181818;
        margin-bottom: 0;
        line-height: normal;
      }
    }

    &:hover {
      .gall-content {
        bottom: 0;
      }

      .gall-img {
        transform: scale(1.1);
      }
    }
  }
}

/*
Team Area Css
======================================================*/
.team-area {
  position: relative;
  z-index: 1;
  overflow: hidden;

  .section-tittle {
    text-align: center;
    margin-bottom: 30px;

    p {
      margin: 0 15%;
    }
  }

  .single-team {
    position: relative;
    overflow: hidden;
    box-shadow: var(--boxShadow);
    margin-bottom: 30px;
    transition: var(--transition);

    .team-img {
      height: 400px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      overflow: hidden;
      transition: 0.3s;
      background: #121e4c;

      img {
        border-radius: 5px;
        transition: 0.3s;
      }
    }

    .team-content {
      padding: 20px;

      h3 {
        color: #121e4c;
        text-align: center;
        margin-bottom: 5px;
      }

      p {
        text-align: center;
        color: #181818;
        margin-bottom: 10px;
      }

      .social {
        text-align: center;
        margin: 0;
        padding: 0;
        width: 100%;
        transition: 0.3s;

        li {
          display: inline-block;
          margin-right: 10px;

          a {
            width: 30px;
            height: 30px;
            background: #121e4c;
            border-radius: 50%;
            line-height: 32px;
            text-align: center;
            transition: 0.3s;
            color: #f5faff;
            transition: 0.3s;

            i {
              font-size: 15px;
            }

            &:hover {
              transform: translateY(-3px);
            }
          }
        }
      }
    }

    &:hover {
      transform: translateY(-5px);
    }
  }
}

/*
Error Area Css
======================================================*/
.error-area {
  position: relative;
  text-align: center;

  h3 {
    color: #e6335a;
    margin-top: 30px;
    font-size: 30px;
  }
}


.home-2-contact {
  .content {
    box-shadow: var(--boxShadow);
    padding: 30px;
    border-radius: var(--borderRadius);

    input.form-control {
      border-radius: var(--borderRadius);
    }

    textarea.form-control {
      border-radius: 4px;
    }
  }

  .contact-img {
    //(assets/images/contact-img2.jpg);
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
    position: relative;
    animation: none;

    img {
      display: none;
    }
  }
}



/*
Footer Area Css
======================================================*/
.footer-area {
  position: relative;
  overflow: hidden;
  //(assets/images/footer-bg.png) no-repeat;
  background-size: cover;
  background-position: center;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    //: url(assets/images/footer-bg1.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    animation: imageChange 1s linear 1s infinite;

    @keyframes imageChange {
      0% {
        opacity: 1;
      }

      75% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }
  }

  .newsletter-form {
    margin-bottom: 25px;
  }

  .content {
    margin-bottom: 30px;

    p {
      color: var(--whiteColor);
      margin-top: 20px;
    }

    .subscribe {
      position: relative;
      max-width: 100%;

      h4 {
        font-size: 24px;
        color: #f5faff;
      }

      input#emails {
        position: relative;
        height: 50px;
        padding-left: 20px;
        border-radius: var(--borderRadius);
        color: var(--whiteColor);

        &::placeholder {
          color: var(--whiteColor);
        }

        &:focus {
          box-shadow: none;
        }

        margin: 20px 0;
        margin-bottom: 10px;
      }

      .newsletter-form {
        position: relative;
      }

      button {
        position: absolute;
        top: 0;
        right: 0;
        border: none;
        height: 50px;

        &::before {
          background-color: var(--whiteColor);
        }

        &::after {
          background-color: var(--whiteColor);
        }

        &:hover {
          background-color: var(--mainColor);
          color: var(--mainColor);
        }
      }

      #validator-newsletter {
        display: inline-block;
        color: #e6335a;
      }
    }

    .social {
      margin: 0;
      padding: 0;

      li {
        display: inline-block;
        margin-right: 10px;

        a {
          width: 35px;
          height: 35px;
          background: var(--mainColor);
          border-radius: 5px;
          line-height: 40px;
          text-align: center;
          transition: 0.3s;
          color: #f5faff;
          transition: 0.3s;

          i {
            font-size: 18px;
          }

          &:hover {
            transform: translateY(-3px);
          }
        }
      }
    }

    h3 {
      font-size: 25px;
      color: #f5faff;
      margin-bottom: 25px;
      position: relative;
      z-index: 1;

      &::before {
        position: absolute;
        content: "";
        width: 3px;
        height: 100%;
        background: #e6335a;
        left: -15px;
        z-index: -1;
      }

      &::after {
        position: absolute;
        content: '';
        background: var(--darkBlueColor);
        width: 7px;
        height: 7px;
        left: -15px;
        top: 0;
        right: 0;
        overflow: hidden;
        animation: line 8s infinite;

        @keyframes line {
          0% {
            transform: translateY(0);
          }

          50% {
            transform: translateY(30px);
          }

          100% {
            transform: translateY(0);
          }
        }
      }
    }

    .footer-list {
      margin: 0;
      padding: 0;

      li {
        line-height: 1.8;
        margin-bottom: 8px;
        position: relative;

        a {
          color: #f5faff;
          font-size: 15px;
          transition: 0.3s;

          i {
            position: absolute;
            top: 4px;
            left: 0;
            color: var(--whiteColor);
            display: inline-block;
            font-size: 15px;
          }

          &:hover {
            letter-spacing: 0.02em;
          }
        }
      }
    }

    .foot-social {
      position: relative;

      li {
        position: relative;
        padding-left: 48px;
        color: #f5faff;
        font-size: 16px;
        margin-bottom: 20px;

        i {
          position: absolute;
          display: inline-block;
          left: 0;
          top: 4px;
          width: 30px;
          height: 30px;
          background: var(--mainColor);
          border-radius: 5px;
          line-height: 31px;
          text-align: center;
          transition: 0.3s;
          color: #f5faff;
        }
      }
    }
  }

  .copy-area {
    margin-top: 70px;
    padding: 20px 0;
    border-top: 1px solid #2a355e;

    .menu {
      margin: 0;
      padding: 0;

      li {
        display: inline-block;
        margin-right: 25px;
        font-size: 15px;

        a {
          color: #f5faff;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    p {
      font-size: 15px;
      color: #f5faff;
      margin-bottom: 0;

      a {
        color: #e6335a;
        font-size: 16px;
        font-weight: 600;

        &:hover {
          color: var(--bodyColor);
        }
      }
    }

    .right {
      float: right;
    }
  }
}

.footer-area-2 {
  background: var(--darkBlueColor);
  position: relative;
  z-index: 1;

  &::before {
    display: none;
  }
}


/*
Pagenavigation Area Css
=====================================================*/
.page-navigation-area {
  text-align: center;
  margin: 20px auto 0;
  display: table;

  .page-link {
    background-color: var(--mainColor);
    box-shadow: var(--boxShadow);
    width: 40px;
    height: 40px;
    line-height: 24px;
    transition: var(--transition);
    font-weight: 700;
    color: var(--whiteColor) !important;
    line-height: 23px;
    border-radius: 50%;

    i {
      margin-right: -4px;
      font-size: 21px;
    }

    &:hover {
      color: var(--whiteColor);
      background-color: var(--darkBlueColor);
    }

    &:focus {
      z-index: 2;
      outline: 0;
      box-shadow: none;
    }
  }

  .page-link.page-links {
    i {
      &::before {
        margin-left: -4px;
      }
    }
  }

  .page-item {
    padding: 0 8px 0;

    &:first-child {
      .page-link {
        border-radius: 50%;
      }
    }

    &:last-child {
      .page-link {
        border-radius: 50%;
      }
    }
  }

  .page-item.active {
    .page-link {
      z-index: 1;
      color: var(--whiteColor);
      background-color: var(--darkBlueColor);
    }
  }
}

/*
Project Area Six Style
======================================================*/
.project-area-six {
  text-align: center;

  .single-project {
    position: relative;
    overflow: hidden;
    transition: all 0.5s;
    margin-bottom: 30px;

    .project-image {
      position: relative;
      transition: all 0.5s;
      height: 380px;
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;

      &.bg-1 {
        //: url(assets/images/gallery/1.jpg);
      }

      &.bg-2 {
        //: url(assets/images/gallery/2.jpg);
      }

      &.bg-3 {
        //: url(assets/images/gallery/3.jpg);
      }

      &.bg-4 {
        //: url(assets/images/gallery/4.jpg);
      }

      &.bg-5 {
        //: url(assets/images/gallery/5.jpg);
      }

      img {
        transition: all 9s;
      }
    }

    .project-content {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      padding: 15px;
      transition: var(--transition);
      z-index: 2;
      text-align: center;
      width: 93%;
      height: 90%;
      justify-content: center;
      align-items: center;
      display: flex;
      background-color: rgba(37, 36, 94, 0.9);
      transform: scaleY(0);

      span {
        color: var(--whiteColor);
        font-weight: 500;
        display: block;
        margin-bottom: 6px;
      }

      h3 {
        margin-bottom: 4px;
        transition: var(--transition);

        &:hover {
          a {
            text-decoration: underline;
          }

        }

        a {
          color: var(--whiteColor);
        }

        &:hover {
          a {
            color: var(--whiteColor);
          }

        }
      }

      .read-more {
        &:hover {
          color: var(--whiteColor);
        }
      }
    }

    &:hover {
      .project-content {
        transform: scaleY(1);
      }

      .project-image {
        transform: scale(2.2) rotate(4deg);
      }
    }
  }
}

/*
Pricing Area Style
======================================================*/
.single-pricing {
  text-align: center;
  background-color: var(--whiteColor);
  box-shadow: var(--boxShadow);
  padding: 30px;
  border-radius: var(--borderRadius);
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin-bottom: 30px;
  border-radius: 50px 0 50px 0;
  transition: var(--transition);

  .pricing-top-heading {
    margin-bottom: 20px;
    position: relative;
    z-index: 1;

    h3 {
      font-size: 25px;
    }

    p {
      font-weight: 600;
      font-size: 14px;
    }
  }

  span {
    font-size: 30px;
    font-family: var(--headingFontFamily);
    color: var(--headingColor);
    font-weight: 700;
    display: block;
    line-height: 1;
    margin: 0 auto 20px;
    background-color: var(--whiteColor);
    box-shadow: var(--boxShadow);
    width: 120px;
    height: 120px;
    line-height: 120px;
    border-radius: 50%;

    sub {
      font-size: 14px;
      color: var(--bodyColor);
    }
  }

  ul {
    text-align: left;
    max-width: 200px;
    margin: 0 auto 25px;

    li {
      margin-bottom: 10px;
      position: relative;
      padding-left: 25px;
      font-weight: 600;

      &:last-child {
        margin-bottom: 0;
      }

      i {
        font-size: 12px;
        display: inline-block;
        width: 20px;
        height: 20px;
        background-color: #daf6fd;
        line-height: 20px;
        border-radius: 50%;
        text-align: center;
        position: absolute;
        top: 5px;
        left: 0;
        color: #1dd3f8;
      }
    }
  }

  .default-btn {
    padding: 15px 35px;
  }

  &:hover {
    transform: translateY(-5px);
  }
}

.popular {
  position: absolute;
  top: -15px;
  right: -50px;
  z-index: 1;
  background-color: red;
  color: var(--whiteColor);
  padding: 35px 40px 10px 35px;
  transform: rotate(45deg)
}

.tab {
  &.quote-list-tab {
    text-align: center;

    .tabs {
      border-radius: 10px;
      display: inline-block;
      border: 1px solid var(--mainColor);
      margin-bottom: 30px;

      li {
        display: inline-block;
        padding: 0 10px;
        font-family: var(--headingFontFamily);
        color: var(--headingColor);
        font-size: 20px;
        font-weight: 700;

        a {
          color: var(--mainColor);
          padding: 10px 20px;
        }
      }

      .current {
        background-color: var(--mainColor);
        border-radius: 10px;

        a {
          color: var(--whiteColor);
        }
      }
    }
  }
}

.tab .tabs_item {
  display: none;
}

.tab .tabs_item:first-child {
  display: block;
}

/*
Services Details Area Style
=====================================================*/
.services-details-area {
  .scrives-item-3 {
    .social-link {
      line-height: 1;

      li {
        display: inline-block;
        margin-right: 5px;

        a {
          width: 30px;
          height: 30px;
          line-height: 38px;
          background-color: var(--mainColor);
          color: var(--whiteColor);
          border-radius: 50%;
          text-align: center;

          i {
            font-size: 20px;
          }

          &:hover {
            -webkit-transform: translateY(-3px);
            transform: translateY(-3px);
          }
        }
      }
    }

    .share {
      float: right;

      a {
        font-size: 20px;
        font-weight: 600;

        i {
          font-size: 20px;
          position: relative;
          top: 3px;
        }
      }
    }
  }

  .services-img.mb {
    margin-bottom: 30px;
  }

  .services-item-wrap {
    margin-bottom: 30px;
  }

  .services-details-text {
    h2 {
      font-size: 35px;
    }
  }
}

/*
Banner Area Three Style
======================================================*/
.main-banner-three {
  background-color: #f5faff;
  position: relative;
  z-index: 1;

  .main-banner-content {
    width: 100%;
    height: 100%;

    .content {
      max-width: 630px;
      margin-left: auto;
      margin-top: 90px;

      h1 {
        font-size: 55px;
        margin-bottom: 15px;
      }

      p {
        margin-bottom: 35px;
      }

      .default-btn {
        margin-top: 20px;
      }

      .slider-btn-wrap {
        .box-btn.border-btn {
          border: 1px solid var(--mainColor);
          color: var(--mainColor);
          margin-left: 20px;

          &:hover {
            color: var(--whiteColor);
            border-color: var(--headingColor);
          }
        }
      }
    }
  }

  .banner-image-slider {
    .banner-image {
      height: 100vh;
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
      overflow: hidden;

      &.banner-slider-bg-1 {
        //: url(assets/images/home-three/banner-img.jpg);
      }

      &.banner-slider-bg-2 {
        //: url(assets/images/home-three/banner-img-2.jpg);
      }
    }

    .owl-dots {
      position: absolute;
      bottom: 20px;
      left: 0;
      right: 0;

      .owl-dot {
        span {
          width: 20px;
          height: 10px;
          transition: var(--transition);
        }

        &.active {
          span {
            background-color: var(--mainColor);
            width: 25px;
          }
        }

        &:hover {
          span {
            background-color: var(--mainColor);
          }
        }
      }
    }

    .owl-prev {
      background-color: var(--mainColor) !important;
      position: absolute;
      bottom: 50%;
      left: 0;
      padding: 0 !important;
      transition: var(--transition);
      transform: translateY(35px);
      opacity: 0;

      i {
        font-size: 20px;
        width: 40px;
        height: 40px;
        line-height: 40px;
        color: var(--whiteColor);
        display: inline-block;
      }

      &:hover {
        background-color: var(--darkBlueColor);

        i {
          color: var(--whiteColor);
        }
      }
    }

    .owl-next {
      background-color: var(--mainColor) !important;
      position: absolute;
      bottom: 50%;
      right: 0;
      padding: 0 !important;
      transition: var(--transition);
      transform: translateY(35px);
      opacity: 0;

      i {
        font-size: 20px;
        width: 40px;
        height: 40px;
        line-height: 40px;
        color: var(--whiteColor);
        display: inline-block;
      }

      &:hover {
        background-color: var(--darkBlueColor) !important;

        i {
          color: var(--whiteColor);
        }
      }
    }

    &:hover {
      .owl-prev {
        opacity: 1;
      }

      .owl-next {
        opacity: 1;
      }
    }
  }
}